<template>
  <!--    第三层-->
  <div class="home-three">
    <img src="../pic/首页/蓝三角.png" alt="" style="width: 66px;height: 53px;position: absolute; top: 135px; left: 183px;">
    <span class="text-big" style="position: absolute;top: 161px;left: 231px">融资工具</span>
    <span class="text-small" style="position: absolute;top: 169.5px;left: 412px">为您推荐当前热门产品</span>
    <img src="../pic/首页/tool1.png" alt="" style="position: absolute;top: 226px;left: 304px;width: 152px;height: 152px">
    <img src="../pic/首页/tool2.png" alt="" style="position: absolute;top: 226px;left: 701px;width: 152px;height: 152px">
    <img src="../pic/首页/tool3.png" alt="" style="position: absolute;top: 226px;left: 1073px;width: 152px;height: 152px">
    <img src="../pic/首页/tool4.png" alt="" style="position: absolute;top: 226px;left: 1418px;width: 152px;height: 152px">
    <span class="tool-text" style="position: absolute;top: 688px;left: 230px">企业授权平台为获取税务、发票企业征信信息，<br>
          根据该信息为企业出具企业融资分析报告，精<br>
          准匹配融资产品。</span>
    <img src="../pic/首页/tool-bg.png" alt="" style="position: absolute;top: 767px;left: 183px;width: 1011px;height: 412px">
    <img src="../pic/首页/tool-right.png" alt="" style="position: absolute;top: 493px;left: 1037px;width: 589px;height: 607px">
  </div>
</template>

<script setup>

</script>

<style scoped>
.home-three{
  width: 100%;
  height: 1179px;
  position: absolute;
  top: 1725px;
  left: 0;
  background-color: #FFFFFF;
  text-align: center;
}
.tool-text{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 26px;
  color: #000000;
  line-height: 26px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-big{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 36px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-small{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #747474;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
</style>