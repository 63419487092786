<template>
  <div class="home-container">
    <hometop/>
    <homefirst/>
    <hometwo/>
    <homethree/>
    <homefour/>
    <homefive/>
    <homesix/>
    <homefooter/>
  </div>
</template>

<script setup>
import {onMounted, defineComponent} from 'vue';
import hometop from '@/components/home-top.vue';
import homefirst from '@/components/home-first.vue';
import hometwo from '@/components/home-two.vue';
import homethree from '@/components/home-three.vue';
import homefour from '@/components/home-four.vue';
import homefive from '@/components/home-five.vue';
import homesix from '@/components/home-six.vue';
import homefooter from '@/components/home-footer.vue';


defineComponent({
  name: 'HomeComponent'
});

onMounted(() => {
  console.log("Window inner width:", window.innerWidth);
  console.log("Document client width:", document.documentElement.clientWidth);
});
</script>

<style scoped>
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}
.home-container{
  overflow: hidden;
  margin: 0;
  padding: 0;
}
</style>