<template>
 <div class="template-container">
  <!-- 首页最上面的黑框 -->
  <div class="home-top"></div>
  <!--最上面有图片做背景的部分-->
  <div class="top-background">
    <!--左上角LOGO-->
    <div class="top-logo">
      <div class="top-logo-circle"></div>
      <div class="top-logo-text">LOGO</div>
    </div>
    <!--位置信息-->
    <div class="top-location">
      <img src="../pic/贷款融资/FinancingWay.png" class="top-location-mark">
      <img src="../pic/贷款融资/FInancingLocation.png" class="top-location-text">
    </div>
    <!--标题-->
    <div class="top-title">
      <img src="../pic/贷款融资/FInancingTitle.png" class="top-title-father">
      <div class="top-title-son">
        <img src="../pic/贷款融资/持牌机构@2x.png" class="top-title-son-text">
        <img src="../pic/贷款融资/直线%2022@2x.png" class="top-title-son-straight">
        <img src="../pic/贷款融资/高效放款@2x.png" class="top-title-son-text">
        <img src="../pic/贷款融资/直线%2022@2x.png" class="top-title-son-straight">
        <img src="../pic/贷款融资/放心无忧@2x.png" class="top-title-son-text">
      </div>
    </div>
    <!--  导航栏-->
    <div class="home-first-route">
      <router-link to="/" class="home-first-route-item" active-class="">首页</router-link>
      <router-link to="/financing" class="home-first-route-item" active-class="">贷款融资</router-link>
      <router-link to="/" class="home-first-route-item" active-class="">融资方案</router-link>
      <router-link to="/" class="home-first-route-item" active-class="">信贷工具</router-link>
      <router-link to="/" class="home-first-route-item" active-class="">产品方案</router-link>
      <router-link to="/" class="home-first-route-item" active-class="">用户登录</router-link>
    </div>
  </div>
  <!--中间整个浅色背景部分-->
  <div class="middle-background">
    <div class="middle-container">
      <!--检索盒子-->
      <div class="middle-searchbox">
        <!--搜索盒的第一行-->
        <div class="middle-searchbox-line-first">
          <!--第一行的文本内容-->
          <div class="middle-searchbox-line-first-textbox">
            <div class="middle-text">开放区域：</div>
            <div v-for="(area, index) in areas" :key="index" class="middle-text">
              {{ area }}
            </div>
          </div>
          <!--第一行的图片内容-->
          <div class="middle-searchbox-line-first-dropdownbox">
            <!-- 地区选择 -->
            <div class="flex flex-wrap items-center gap-4">
              <el-dropdown placement="bottom" v-model="selectedProvince">
                <el-button>{{ selectedProvince || '省级' }}</el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                        v-for="(province, index) in provinces"
                        :key="index"
                        @click="selectProvince(province)"
                    >
                      {{ province }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div class="flex flex-wrap items-center gap-4">
              <el-dropdown placement="bottom" v-model="selectedCity">
                <el-button>{{ selectedCity || '市级' }}</el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                        v-for="(city, index) in cities"
                        :key="index"
                        @click="selectCity(city)"
                    >
                      {{ city }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div class="flex flex-wrap items-center gap-4">
              <el-dropdown placement="bottom" v-model="selectedDistrict">
                <el-button>{{ selectedDistrict || '区级' }}</el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                        v-for="(district, index) in districts"
                        :key="index"
                        @click="selectDistrict(district)"
                    >
                      {{ district }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </div>

        <!--搜索盒的第二行-->
        <div class="middle-searchbox-line-second">
          <div class="middle-text">排序：</div>
            <div
                class="middle-searchbox-line-second-option"
                v-for="(option, index) in options"
                :key="index"
                :class="{ active: selectedIndex === index }"
                @click="selectOption(index)"
            >
              {{ option }}
            </div>
        </div>
        <!--搜索盒的第三行-->
        <div class="middle-searchbox-line-third">
            <div class="middle-text">快速筛选：</div>
<!--          快速筛选内容-->
            <div class="middle-searchbox-line-third-siftbox">
              <div class="middle-searchbox-line-third-sift">产品筛选</div>
              <div class="middle-searchbox-line-third-sift">企业工商信息匹配</div>
            </div>
<!--          搜索框-->
            <div class="middle-searchbox-line-third-search">
              <input class="middle-searchbox-line-third-search-input" type="text" placeholder="产品名称搜索">
              <button class="middle-searchbox-line-third-search-button">
                <img src="../pic/贷款融资/路径%2036@2x.png" class="middle-searchbox-line-third-search-img" alt="搜索">
              </button>
            </div>
        </div>
      </div>
      <!--公告板-->
      <div class="middle-announcement">
        <div class="middle-announcement-title">
          产品公告
        </div>
        <div class="middle-announcement-content">
          上线500万供货模式
        </div>
      </div>
      <!--广告牌-->
      <div class="middle-ad">
        <img src="../pic/贷款融资/组%2088@2x.png" class="middle-ad-pic">
      </div>
      <!--主要部分-->
      <div class="middle-mian">

      </div>
    </div>
  </div>
 </div>

  <div class="bottom-background">

  </div>
</template>

<script setup>import { ref, onMounted } from 'vue';

// 地区选择
const areas = ref(['全部', '上海市', '深圳市', '东莞市', '杭州市']);

// 第二行的排序选项
const options = ref(['综合排序', '按利率', '按额度']);
const selectedIndex = ref(null);

const provinces = ref(['河北省', '河南省', '山东省']);
const citiesData = {
  '河北省': ['石家庄市', '邯郸市', '承德市'],
  '河南省': ['郑州市', '洛阳市', '南阳市'],
  '山东省': ['济南市', '青岛市', '烟台市'],
};
const districtsData = {
  '石家庄市': ['赞皇县', '高邑县', '赵县'],
  '邯郸市': ['丛台区', '复兴区', '峰峰矿区'],
  '承德市': ['双桥区', '承德县', '兴隆县'],
};

const selectedProvince = ref('');
const selectedCity = ref('');
const selectedDistrict = ref('');
const cities = ref([]);
const districts = ref([]);

// 选择省级
const selectProvince = (province) => {
  selectedProvince.value = province;
  selectedCity.value = '';
  selectedDistrict.value = '';
  cities.value = citiesData[province] || [];
  districts.value = []; // 清空区的选择
};

// 选择城市
const selectCity = (city) => {
  selectedCity.value = city;
  selectedDistrict.value = '';
  districts.value = districtsData[city] || [];
};

// 选择区
const selectDistrict = (district) => {
  selectedDistrict.value = district;
};

// 选择排序选项
const selectOption = (index) => {
  selectedIndex.value = index;
};

// 组件挂载后输出窗口和文档宽度
onMounted(() => {
  console.log("Window inner width:", window.innerWidth);
  console.log("Document client width:", document.documentElement.clientWidth);
});
</script>
<script>export default {
  name: 'FinancingComponent',
};
</script>
<style scoped>
.template-container {
  height: 100vh;
  overflow-y: auto;
}
.top-background {
  background-image: url("@/pic/贷款融资/组 90@2x.png");
  width: 100%;
  background-color: #2953FF;
  height: 342px;
  background-size: cover;
  background-position: top ;
  background-repeat: repeat;
  overflow: hidden;
  position: absolute;
  top: 43px;
  left: 0px;
}
.top-logo {
  position: absolute;
  top:76px;
  left: 139px;
  width: 200px;
  height: 40px;
}
.top-logo-circle{
  position: absolute;
  margin-right: 4px;
  width: 36px;
  height: 36px;
  background: #2953FF;
  border-radius: 50%;
}
.top-logo-text{
  position: absolute;
  left: 40px;
  width: 99px;
  height: 48px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 34px;
  color: #000000;
  line-height: 34px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.top-location{
  position: absolute;
  left: 324px;
  top:86px;
}
.top-location-mark{
  width: 12.96px;
  height: 17.28px;
}
.top-location-text{
  width: 67px;
  height: 20px;
  position: absolute;
  left: 20px;
  bottom: 2.72px;
}
.top-title{
  position: absolute;
  left: 154px;
  top: 168px;
}
.top-title-father{
  width: 384px;
  height: 67px;
}
.top-title-son{
  position: absolute;
  top:70px;

}
.top-title-son-text{
  width: 64px;
  height: 22px;
  margin-right: 19px;
}
.top-title-son-straight{
  height: 20px;
  margin-right: 19px;
}
.middle-background{
  background-image: url("@/pic/贷款融资/矩形 65@2x.png");
  width: 100%;
  height: 1446px;
  position: absolute;
  top: 342px;
  left: 0px;
}
.middle-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1446px;
  width: 1440px;
}
.middle-text{
  height: 22px;
  width: auto;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  white-space: nowrap;
  user-select: none;
}
.middle-searchbox{
  width: 827px;
  height: 217px;
  position: absolute;
  top: 20px;
  left: 139px;
  background-image: url("@/pic/贷款融资/矩形 66@2x.png");
  border-radius: 1px 1px 1px 1px;
}
.middle-searchbox-line-first{
  position: absolute;
  top: 18px;
  left: 0px;
  display: flex;
  align-items: center;
}
.middle-searchbox-line-first-dropdownbox{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 434px;
  margin-top: 3px;
}
.middle-searchbox-line-first-textbox{
  position: absolute;
  top: 7px;
  left: 25px;
  display: flex;
  justify-content: space-around;
  gap: 17px;
}
.middle-searchbox-line-second{
  position: absolute;
  top: 96px;
  left: 26px;
  display: flex;
  align-items: center;
  gap: 18px;
}
.middle-searchbox-line-second-option {
  font-family: PingFang SC;
  font-size: 16px;
  color: #000;
  line-height: 22px;
  cursor: pointer;
}
.middle-searchbox-line-second-option.active {
  color: blue;
}

.middle-searchbox-line-third {
  position: absolute;
  top: 159px;
  left: 25px;
  display: flex;
  align-items: center;
}
.middle-searchbox-line-third-siftbox {
  display: flex;
  margin-right: 20px;
}
.middle-searchbox-line-third-sift {
  background-color: #F0F3FF;
  color: #2953FF;
  border: 1px solid #2953FF;
  border-radius: 3px;
  height: 37px;
  margin-right: 11px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  white-space: nowrap;
}
.middle-searchbox-line-third-search {
  display: flex;
  margin-left: 116px;
}
.middle-searchbox-line-third-search-input {
  width: 213px;
  height: 36px;
  border: 1px solid #DCDCDD;
  border-radius: 3px 0 0 3px;
  outline: none;
  padding: 0 10px;
}
.middle-searchbox-line-third-search-button {
  width: 39px;
  height: 38px;
  border: 1px solid #DCDCDD;
  border-left: none;
  background-color: #2953FF;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
}
.middle-searchbox-line-third-search-img {
  width: 14.81px;
  height: 16.6px;
  margin-top: 3px;
}

.middle-announcement{
  width: 315px;
  height: 109px;
  position: absolute;
  top: 20px;
  left: 985px;
  background-image: url("@/pic/贷款融资/矩形 93@2x.png");
  border-radius: 5px  5px  5px  5px;
}
.middle-announcement-title{
  width: 80px;
  height: 28px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  position: absolute;
  top: 18px;
  left: 25px;
}
.middle-announcement-content{
  width: 141px;
  height: 22px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  position: absolute;
  top: 64px;
  left: 25px;
}
.middle-ad{
  width: 315px;
  height: 185px;
  position: absolute;
  top: 147px;
  left: 985px;
}
.middle-ad-pic{
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.middle-mian{
  width: 827px;
  height: 937px;
  position: absolute;
  top: 257px;
  left: 139px;
  background: #FFFFFF;
  border-radius: 1px 1px 1px 1px;
}


.bottom-background{
  width: 100%;
  height: 439px;
  position: absolute;
  top: 1501px;
  left: 0px;
  background: #252931;
  border-radius: 0px 0px 0px 0px;
}
.home-top {
  width: 100%;
  height: 43px;
  background-image: url('@/pic/首页/top.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}
.home-first-route {
  margin-top: 20px;
}
.home-first-route-item:first-child {
  margin-left: 672px;
}
.home-first-route-item {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-right: 54px;
  text-decoration: none;
}
.home-first-route-item:last-child {
  margin-right: 0;
}
.home-first-route-item.active {
  font-weight: 600;
  font-size: 16px;
}
.home-first-route-item.active::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 25%;
  width: 50%; /* 达到居中效果 */
  height: 4px;
  background-color: #4b4bdc;
  border-radius: 2px; /* 圆润效果 */
}
</style>