import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';//首页
import Financing from '@/views/Financing.vue';//贷款融资

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/financing',
    name: 'Financing',
    component: Financing,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
