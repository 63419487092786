<template>
  <!--   第四层 -->
  <div class="home-four">
    <img src="../pic/首页/蓝三角.png" alt="" style="width: 66px;height: 53px;position: absolute; top: 81px; left: 183px;">
    <span class="text-big" style="position: absolute;top: 109px;left: 231px">助力中小微企业融资</span>
    <span class="text-small" style="position: absolute;top: 115.5px;left: 602px">高效、精准、快捷</span>
    <img src="../pic/首页/矩形%2029@2x.png" alt="" style="position: absolute;top: 174px;left: 183px;width: 9px;height: 29px">
    <span class="text-big" style="position: absolute;top: 187.5px;left: 202px;font-size: 31px">实时融资动态</span>
    <img src="../pic/首页/矩形%2030@2x.png" alt="" style="position: absolute;top: 174px;left: 1036px;width: 9px;height: 29px">
    <span class="text-big" style="position: absolute;top: 187.5px;left: 1054px;font-size: 31px">累计服务</span>
    <img src="../pic/首页/50842302b4c5d7e6ecb27eed1dfa3425be4a12383fb4f-UsFKdW@2x.png" alt="" style="position: absolute;top: 286px;left: 1136px;width: 437px;height: 424px">
    <span class="text-four" style="position: absolute;top: 299.5px;left: 1282px;">今日对接服务数</span>
    <span class="text-big" style="position: absolute;top: 259px;left: 1322px;">740</span>
    <span class="text-four" style="position: absolute;top: 394.5px;left: 1082px;">入驻金融机构</span>
    <span class="text-big" style="position: absolute;top: 354px;left: 1109px;">50＋</span>
    <span class="text-four" style="position: absolute;top: 394.5px;left: 1496px;">融资产品丰富</span>
    <span class="text-big" style="position: absolute;top: 354px;left: 1513px;">100＋</span>
    <span class="text-four" style="position: absolute;top: 606.5px;left: 1100px;">累计服务客户</span>
    <span class="text-big" style="position: absolute;top: 566px;left: 1117px;">970＋</span>
    <span class="text-four" style="position: absolute;top: 606.5px;left: 1505px;">累计撮合金额</span>
    <span class="text-big" style="position: absolute;top: 566px;left: 1496px;">1,000亿＋</span>
  </div>
</template>

<script setup>

</script>

<style scoped>
.home-four{
  width: 100%;
  height: 800px;
  background-color: rgba(244, 248, 255, 1);
  position: absolute;
  top: 2904px;
  left: 0;
}
.text-big{
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 36px;
  color: #292929;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-small{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #747474;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.text-four{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 21px;
  color: #666666;
  line-height: 0;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
</style>