<template>
  <div class="home-top"></div>
</template>

<script setup>

</script>

<style scoped>
.home-top {
  width: 100%;
  height: 43px;
  background-image: url('@/pic/首页/top.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}
</style>